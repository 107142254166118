function Portfolio() {
  return (
    <div className="sm:text-center lg:text-left">
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="-my-8 divide-y-2 divide-gray-100">
            <div className="py-8 flex flex-wrap md:flex-nowrap">
              <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                <span className="font-semibold title-font uppercase text-gray-700">
                  Igangværende opgaver
                </span>
                <span className="mt-1 text-gray-500 text-sm">
                  Maj 2023 Underrådgiver til Artelia Danmark ifm skitseprojekt for cykelstier på Mørdrupvej, Espegærde.
                </span>
              </div>
              <div className="md:flex-grow">
                <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                  Ressourceperson i Lyngby-Taarbæk Kommune
                </h2>
                <p className="l eading-relaxed">
                  Bistår kommunen med de afsluttede arbejder herunder matrikulære ændringer efter forlængelse af Firskovvej, opbygning af den eksisterende del af Firskovvej samt etablering af fortov, cykelsti og udvidelse af baneoverskæring på Nørgaardsvej.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-2">
            <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">
              Afsluttede opgaver
            </h1>
          </div>
          <div className="lg:w-2/3 w-full mx-auto overflow-auto">
            <table className="table-auto w-full text-left whitespace-no-wrap mb-12">
              <tbody>
              <tr>
                  <td className="px-4 py-3">
                    April - maj 2023 Trafiksikkerheds- og tilgængelighedsrevision trin 3 Københavns Kommune. Genopretning 2023. Rønnegade og Hildursgade
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-3">
                    Trafiksikkerhedsrevision trin 3 af 3 projekter til
                    optimering af parkeringsforhold i København, Københavns
                    Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    Trafiksikkerhedsrevision trin 3 af projekter til sikring af
                    skoleveje i København, Københavns Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    Trafiksikkerhedsrevision trin 3 af grønne stiruter,
                    Schønherr A/S
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Trafiksikkerhedsrevision trin 2 af grønne stiruter,
                    Schønherr A/S
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Trafiksikkerhedsrevision trin 2 af projekt til ændring af
                    cykelstiforløb på Amager Boulevard i forlængelse af
                    Langebro, Københavns Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Trafiksikkerhedsrevision trin 1 Farumruten, Klausdalsbrovej,
                    Gladsaxe Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Tilgængelighedsrevision trin 3 af Frederiksborgvej, MOE A/S
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Tilgængelighedsrevision trin 3 af krydset
                    Åboulevard/Rosenørns Allé, Søruten, MOE A/S
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Tilgængelighedsrevision trin 2 Århusgadekvarteret, MOE &amp;
                    Brødsgaard A/S
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex flex-col text-center w-full mb-12">
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Herudover har jeg gennemført en del revisioner i MOE A/S, hvor de
              seneste har været:
            </p>
          </div>
          <div className="lg:w-2/3 w-full mx-auto overflow-auto">
            <table className="table-auto w-full text-left whitespace-no-wrap">
              <thead>
                <tr>
                  <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                    Hos MOE A/S
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-3">
                    Trafiksikkerheds- og tilgængelighedsrevision trin 3, Det
                    Grønne Loop i Nordhavn, By &amp; Havn I/S
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    Trafiksikkerhedsrevision trin 3 af Tre fortove, Leifsgade og
                    Gullfossgade, Københavns Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-gray-200 px-4 py-3">
                    Trafiksikkerheds- og tilgængelighedsrevision trin 2 og 3
                    Vigerslevvej og parkindgange, Københavns Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Tilgængelighedsrevision trin 2 og 3 Hans Tausens Plads,
                    Københavns Kommune
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Trafiksikkerheds- og tilgængelighedsrevision trin 3-
                    Byggemodning, Nivåvej, Nivåvej, Nivå - del 1 ApS
                  </td>
                </tr>
                <tr>
                  <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3">
                    Trafiksikkerheds- og tilgængelighedsrevision trin 3-
                    Forbedrede cykelforhold i Tingbjerg, Københavns Kommune
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/*
          <div className="flex pl-4 mt-4 lg:w-2/3 w-full mx-auto">
            <a className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
              Hør mere
              <svg
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
          */}
        </div>
      </section>
    </div>
  );
}
export default Portfolio;
